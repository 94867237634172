import React, { useEffect } from 'react'

import { isMobile } from 'react-device-detect'
import TagManager from 'react-gtm-module'
import { BrowserRouter, Redirect, Route, Router, Switch } from 'react-router-dom'

import TextToSpeech from '../components/textToSpeech/TextToSpeech'
// import { TinaAuth } from '../containers/tinacms/TinaAuth'
import { Addressdes } from '../pages/AddressDes'
import { AddressForm } from '../pages/AddressForm'
import { AddTeamMember } from '../pages/AddTeamMember'
import { AccountSettings } from '../pages/AdminPanel/AdminSettingsPage/AccountDetails'
import { ArchiveSettings } from '../pages/AdminPanel/AdminSettingsPage/ArchiveAccount'
import { EditprofileSettings } from '../pages/AdminPanel/AdminSettingsPage/EditProfile'
import { ManageAdmin } from '../pages/AdminPanel/AdminSettingsPage/ManageAdmin'
import { PasswordSettings } from '../pages/AdminPanel/AdminSettingsPage/password'
import { AzureLogin } from '../pages/AdminPanel/AzureLogin'
import Bin from '../pages/AdminPanel/Bin'
import { Feedback } from '../pages/AdminPanel/Feedback'
import { Inbox } from '../pages/AdminPanel/Inbox'
// import { LoginPage } from '../pages/AdminPanel/Login'
import { Message } from '../pages/AdminPanel/Message'
import { NotificationsPage } from '../pages/AdminPanel/NotificationsPage'
import { ReportProblem } from '../pages/AdminPanel/Report-problem'
import { ResetpasswordPage } from '../pages/AdminPanel/reset-password'
import Sent from '../pages/AdminPanel/Sent'
import { SignupPage } from '../pages/AdminPanel/Signup'
import { Users } from '../pages/AdminPanel/super_admin/admins/Users'
import ForgotPassword from '../pages/AdminPanel/super_admin/Forgot-password'
import { OrderKit } from '../pages/AdminPanel/super_admin/forms/OrderKit'
import { SyncUser } from '../pages/AdminPanel/super_admin/forms/SyncUser'
import { QuickDashboard } from '../pages/AdminPanel/super_admin/QuickDashboard'
import { ArchiveList } from '../pages/AdminPanel/super_admin/settings/ArchiveList'
import { EditManager } from '../pages/AdminPanel/super_admin/settings/EditManager'
import { EditUserPermission } from '../pages/AdminPanel/super_admin/settings/EditUserPermission'
import { Overview } from '../pages/AdminPanel/super_admin/settings/Overview'
import { SetDefaultPermissions } from '../pages/AdminPanel/super_admin/settings/SetDefaultPermissions'
import { UsersList } from '../pages/AdminPanel/super_admin/settings/UsersList'
import { Agebarrier } from '../pages/Agebarrier'
import { BrookClinic } from '../pages/BrookClinic'
import { CallYou } from '../pages/CallYou'
import { ConfirmAddress } from '../pages/ConfirmAddress'
import { ConfirmEmail } from '../pages/ConfirmEmail'
import { Consent } from '../pages/consent'
import { ConsentBarrier } from '../pages/ConsentBarrier'
import { ContactBrook } from '../pages/ContactBrook'
import { ContactUserName } from '../pages/ContactUserName'
import { CustomiseKitOne } from '../pages/CustomiseKitOne'
import { CustomiseKitTwo } from '../pages/CustomiseKitTwo'
import { CustomiseYourKit } from '../pages/CustomiseYourKit'
import { DateOfBirth } from '../pages/DateOfBirth'
import { EditYourKit } from '../pages/EditYourKit'
import { Elgibilitypass } from '../pages/Eligibilitypass'
import { Ethnicity } from '../pages/Ethnicity'
import { External } from '../pages/External'
import { FinalScreen } from '../pages/FinalScreen'
import { FullLayout } from '../pages/FullLayout'
import { Genitals } from '../pages/Genitals'
import { Hepatitis } from '../pages/Hepatitis'
import { HepatitisVaccination } from '../pages/HepatitisVaccination'
import { MobileNumber } from '../pages/MobileNumber'
import { MonitoringQns } from '../pages/MonitoringQns'
import { Postage } from '../pages/Postage'
import { Postcode } from '../pages/Postcode'
import { Postcodeageverification } from '../pages/PostcodeAgeVerification'
import { Postcodebarrier } from '../pages/Postcodebarrier'
import { PostGenital } from '../pages/PostGenital'
import { PreContact } from '../pages/PreContact'
import { PreInfo } from '../pages/PreInfo'
import { RiskOfHepatitis } from '../pages/RiskOfHepatitis'
import { SelectGender } from '../pages/SelectGender'
import Contraception from '../pages/SignpostingTool/contraception'
import EmergencyContraception from '../pages/SignpostingTool/EmergencyContraception'
import HavingSex from '../pages/SignpostingTool/HavingSex'
import { Help } from '../pages/SignpostingTool/Help'
import SignpostingHome from '../pages/SignpostingTool/Home'
import MythBusting from '../pages/SignpostingTool/MythBusting'
import { OrderConfirmation } from '../pages/SignpostingTool/OrderConfirmation'
import { PostSubmission } from '../pages/SignpostingTool/PostSubmission'
// import  { Notfound }  from '../pages/SignpostingTool/NotFound'
import STI from '../pages/SignpostingTool/STI'
import { SwitchDesktop } from '../pages/SignpostingTool/SwitchDesktopScreen'
import { Under16sti } from '../pages/SignpostingTool/under16STI'
import UnprotectedSex from '../pages/SignpostingTool/UnprotectedSex'
import { Successpostcode } from '../pages/Successpostcode'
import { Symptombarrier } from '../pages/Symptombarrier'
import { TestKitFromBrook } from '../pages/TestKitFromBrook'
import { ThankYou } from '../pages/ThankYou'
import { Typeofsex } from '../pages/TypeOfSex'
import { UserDetailNotEligible } from '../pages/UserDetailNotEligible'
import { UserDetailsConsent } from '../pages/UserDetailsConsent'
import { Userunder18 } from '../pages/Userunder18'
import { Vaginalsex } from '../pages/vaginalsex'
import { ValidateLogin } from '../pages/ValidateLogin'
import { Welcome } from '../pages/Welcome'
import { WhatNext } from '../pages/WhatNext'
import appUrls from '../services/AppURLs'
import { history } from '../utils/history'
// import { ServiceUnavailable } from '../pages/ServiceUnavailable'

// list of form route names
const formPaths: Array<string> = [
  'cms-login',
  'preinfo',
  'genitals',
  'postage',
  'postcode',
  'postcode-age',
  'typeofsex',
  'vaginalsex',
  'postcodebarrier',
  'agebarrier',
  'userunder18',
  'symptombarrier',
  'successpostcode',
  'eligibilitypass',
  'postGenital',
  'brookClinic',
  'addressdes',
  'address-form',
  'hometest',
  'user-consent',
  'preContact',
  'contactUserName',
  'dateOfBirth',
  'mobileNumber',
  'consent',
  'user-noteligible',
  'confirm-address',
  'monitor-question',
  'ethnicity',
  'selectgender',
  'confirm-email',
  'thankyou',
  'callyou',
  'finalScreen',
  'under16sti',
  'contact-brook',
  'whatnext',
  'consent-barrier',
  'order-confirmation',
  'post-submission',
  'risk-hepatitis',
  'hepatitis-vaccination',
  'test-kit',
  'edit-kit',
  'customise-kit',
  'customise-kit1',
  'customise-kit2',
  'hepatitis',
]
const accessToken = localStorage.getItem('access-token')

/**
 * Form route
 * @returns router with components related with forms
 */
const PrivateRoute = ({ component, ...rest }: any) => {
  // if (TextToSpeech.getInstance()) {
  TextToSpeech.getInstance().playVoice(null, 'stop')
  // }
  const routeComponent = (props: any) =>
    !localStorage.getItem('access-token')
      ? React.createElement(component, props)
      : React.createElement(component, props)
  // <Redirect to="/"/>
  return <Route {...rest} render={routeComponent} />
}

const RedirectToParent = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    !localStorage.getItem('access-token')
      ? React.createElement(component, props)
      : React.createElement(component, props)
  // <Redirect to="/"/>
  return <Route {...rest} render={routeComponent} />
}

/**
 * Form route
 * @returns router with components related with forms
 */
const ProtectedRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    localStorage.getItem('access-token') ? (
      <Redirect to={`${appUrls.admin.orderKit}?filters=Flagged`} />
    ) : (
      React.createElement(component, props)
    )
  return <Route {...rest} render={routeComponent} />
}

/**
 * Form route
 * @returns router with components related with forms
 */
const ProtecteDesktopRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isMobile ? (
      localStorage.getItem('access-token') ? (
        <Redirect to="/switch-desktop" />
      ) : (
        <Redirect to={`${appUrls.admin.orderKit}?filters=Flagged`} />
      )
    ) : (
      React.createElement(component, props)
    )
  return <Route {...rest} render={routeComponent} />
}

/**
 * Form route
 * @returns router with components related with forms
 */
const ProtectedBrowserRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isMobile ? <Redirect to="/switch-desktop" /> : React.createElement(component, props)
  return <Route {...rest} render={routeComponent} />
}

function NotFoundBrookRedirect() {
  useEffect(() => {
    // Get query parameters from the URL
    const queryParams = new URLSearchParams(window.location.search)

    // Get a specific query parameter value
    const paramValue = queryParams.get('access-user-key')

    if (paramValue) {
      localStorage.setItem('access-user-key', paramValue)
      window.location.reload()
    } else {
      window.location.href = process.env.REACT_APP_ENABLE_DOWNTIME_REDIRECT_URL || 'https://www.brook.org.uk/service-unavailable/';
    }
  }, [])

  return <div>{/* You can render a message or component here if needed */}</div>
}

function FormRouteDowntime() {
  return (
    <div>
      <Route exact path="*" component={NotFoundBrookRedirect} />
    </div>
  )
}

function FormRoute() {
  useEffect(() => {
    if (formPaths.includes(window.location.pathname.substr(1))) {
      if (window.location.pathname.substr(1) !== 'hometest') {
        let result = window.confirm('You will lose your data. Redirecting to home page.')
        // // if(accessToken){
        // //   window.location.href = '/user/login';
        // //   return;
        // // }
        window.location.href = '/hometest'
      }
    }
  }, [])

  return (
    <div>
      {/* <PrivateRoute exact path="/" component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.signPosting.havingSex} component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.signPosting.help} component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.signPosting.unprotectedSex} component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.signPosting.sti} component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.signPosting.contraception} component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.signPosting.emergencyContraception} component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.signPosting.mythBusting} component={() => {
          window.location.href = appUrls.sti.home;
          return null;
      }} />
      <PrivateRoute path={appUrls.switchDesktop} component={SwitchDesktop} /> */}
      <PrivateRoute exact path="/" component={SignpostingHome} />
      <PrivateRoute path={appUrls.signPosting.havingSex} component={HavingSex} />
      <PrivateRoute path={appUrls.signPosting.help} component={Help} />
      <PrivateRoute path={appUrls.signPosting.unprotectedSex} component={UnprotectedSex} />
      <PrivateRoute path={appUrls.signPosting.sti} component={STI} />
      <PrivateRoute path={appUrls.signPosting.contraception} component={Contraception} />
      <PrivateRoute path={appUrls.signPosting.emergencyContraception} component={EmergencyContraception} />
      <PrivateRoute path={appUrls.signPosting.mythBusting} component={MythBusting} />
      <PrivateRoute path={appUrls.switchDesktop} component={SwitchDesktop} />

      <Route path="/validate-login" component={ValidateLogin} />
      <Route path="/external" component={External} />
      <Route path={appUrls.sti.home} component={Welcome} />
      {/* <Route path={appUrls.sti.home} component={ServiceUnavailable} /> */}
      {/* <Route path={appUrls.sti.cmsLogin} component={TinaAuth} /> */}

      <PrivateRoute path={appUrls.sti.preinfo} component={PreInfo} />
      <PrivateRoute path="/genitals" component={Genitals} />
      <PrivateRoute path="/postage" component={Postage} />
      <PrivateRoute path="/postcode" component={Postcode} />
      <PrivateRoute path="/postcode-age" component={Postcodeageverification} />
      <PrivateRoute path="/typeofsex" component={Typeofsex} />
      <PrivateRoute path="/vaginalsex" component={Vaginalsex} />
      <PrivateRoute path="/postcodebarrier" component={Postcodebarrier} />
      <PrivateRoute path="/agebarrier" component={Agebarrier} />
      <PrivateRoute path="/userunder18" component={Userunder18} />
      <PrivateRoute path="/symptombarrier" component={Symptombarrier} />
      <PrivateRoute path="/successpostcode" component={Successpostcode} />
      <PrivateRoute path="/eligibilitypass" component={Elgibilitypass} />
      <PrivateRoute path="/postGenital" component={PostGenital} />
      <PrivateRoute path="/brookClinic" component={BrookClinic} />
      <PrivateRoute path="/addressdes" component={Addressdes} />
      <PrivateRoute path="/address-form" component={AddressForm} />
      <PrivateRoute path="/user-consent" component={UserDetailsConsent} />
      <PrivateRoute path="/preContact" component={PreContact} />
      <PrivateRoute path="/contactUserName" component={ContactUserName} />
      <PrivateRoute path="/dateOfBirth" component={DateOfBirth} />
      <PrivateRoute path="/mobileNumber" component={MobileNumber} />
      <PrivateRoute path="/consent" component={Consent} />
      <PrivateRoute path="/under16sti" component={Under16sti} />
      <PrivateRoute path="/consent-barrier" component={ConsentBarrier} />
      <PrivateRoute path="/user-noteligible" component={UserDetailNotEligible} />
      <PrivateRoute path="/confirm-address" component={ConfirmAddress} />
      <PrivateRoute path="/monitor-question" component={MonitoringQns} />
      <PrivateRoute path="/ethnicity" component={Ethnicity} />
      <PrivateRoute path="/selectgender" component={SelectGender} />
      <PrivateRoute path="/whatnext" component={WhatNext} />
      <PrivateRoute path="/confirm-email" component={ConfirmEmail} />
      <PrivateRoute path="/order-confirmation" component={OrderConfirmation} />
      <PrivateRoute path="/post-submission" component={PostSubmission} />
      <PrivateRoute path="/thankyou" component={ThankYou} />
      <PrivateRoute path="/callyou" component={CallYou} />
      <PrivateRoute path="/finalScreen" component={FinalScreen} />
      <PrivateRoute path="/contact-brook" component={ContactBrook} />
      <PrivateRoute path="/risk-hepatitis" component={RiskOfHepatitis} />
      <PrivateRoute path="/hepatitis-vaccination" component={HepatitisVaccination} />
      <PrivateRoute path="/test-kit" component={TestKitFromBrook} />
      <PrivateRoute path="/edit-kit" component={EditYourKit} />
      <PrivateRoute path="/customise-kit" component={CustomiseYourKit} />
      <PrivateRoute path="/customise-kit1" component={CustomiseKitOne} />
      <PrivateRoute path="/customise-kit2" component={CustomiseKitTwo} />
      <PrivateRoute path="/hepatitis" component={Hepatitis} />

      <PrivateRoute
        path={appUrls.admin.authOld.login}
        component={() => {
          window.location.href = appUrls.admin.auth.login
          return null
        }}
      />
      <PrivateRoute
        path={appUrls.admin.authOld.signup}
        component={() => {
          window.location.href = appUrls.admin.auth.signup
          return null
        }}
      />
      {/* <Route component={Notfound} /> */}
      <ProtecteDesktopRoute path={appUrls.admin.auth.login} component={AzureLogin} />
      <ProtecteDesktopRoute path={appUrls.admin.auth.azurelogin} component={AzureLogin} />
      <ProtecteDesktopRoute path={appUrls.admin.auth.signup} component={SignupPage} />
      <ProtectedBrowserRoute path={`${appUrls.admin.auth.resetPassword}/:token`} component={ResetpasswordPage} />
      <ProtectedBrowserRoute path={appUrls.admin.auth.forgotPassword} component={ForgotPassword} />
      <ProtectedBrowserRoute path={appUrls.admin.orderKit} component={OrderKit} />
      <ProtectedBrowserRoute path={appUrls.admin.syncUser} component={SyncUser} />
      <ProtectedBrowserRoute path={appUrls.admin.dashboard} component={QuickDashboard} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.accounts} component={AccountSettings} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.password} component={PasswordSettings} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.editProfile} component={EditprofileSettings} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.archiveAccount} component={ArchiveSettings} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.manageRoles} component={Overview} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.managerPermission} component={SetDefaultPermissions} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.userPermission} component={UsersList} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.archivePermission} component={ArchiveList} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.editManager} component={EditManager} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.editUser} component={EditUserPermission} />
      <ProtectedBrowserRoute path={appUrls.admin.settings.addTeamMember} component={AddTeamMember} />
      <ProtectedBrowserRoute path={appUrls.admin.users.users} component={Users} />
      <ProtectedBrowserRoute path={appUrls.admin.users.problem} component={ReportProblem} />
      <ProtectedBrowserRoute path={appUrls.admin.users.feedback} component={Feedback} />
      <ProtectedBrowserRoute path={appUrls.admin.users.message} component={Message} />
      <ProtectedBrowserRoute path={appUrls.admin.users.inbox} component={Inbox} />
      <ProtectedBrowserRoute path={appUrls.admin.users.sent} component={Sent} />
      <ProtectedBrowserRoute path={appUrls.admin.users.bin} component={Bin} />
      <ProtectedBrowserRoute path={appUrls.admin.users.notifications} component={NotificationsPage} />
      <ProtectedBrowserRoute path={appUrls.admin.users.manageRequest} component={ManageAdmin} />
    </div>
  )
}

/**
 * App route
 * @returns parent of the routes
 */

if (process.env.REACT_APP_GA_TAGMANAGER) {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GA_TAGMANAGER,
    dataLayerName: 'PageDataLayer',
  }
  TagManager.initialize(tagManagerArgs)
}

const AppRoute = (): JSX.Element => {
  useEffect(
    () =>
      history.listen(() => {
        if (process.env.REACT_APP_GA_TAGMANAGER) {
          TagManager.dataLayer({
            dataLayer: {
              event: 'pageview',
              pageUrl: history.location.pathname,
            },
          })
        }
      }),
    []
  )

  return (
    <>
      <BrowserRouter>
        <Router history={history}>
          <FullLayout formPaths={formPaths} />
          <Switch>
            {process.env.REACT_APP_ENABLE_DOWNTIME === 'true' ? (
              localStorage.getItem('access-user-key') &&
              localStorage.getItem('access-user-key') === process.env.REACT_APP_ENABLE_DOWNTIME_KEY ? (
                <FormRoute />
              ) : (
                <FormRouteDowntime />
              )
            ) : (
              <FormRoute />
            )}
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  )
}

export default AppRoute
