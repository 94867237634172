import React, { useContext } from 'react';

import { useLocation } from 'react-router-dom';

// import Icon from '../assets/images/brook_logo_new.svg';
import { ThemeContext } from '../context/provider/ThemeContext';
import appUrls from '../services/AppURLs';
import '../styles/AdminStyle/adminLayout.css';

export const FullLayout = (props: any): JSX.Element => {
  const location = useLocation();
  const { theme } = useContext(ThemeContext)
  // window.location.origin
  return (
    <div>
      {
        (props.formPaths && props.formPaths.includes(window.location.pathname.substr(1))) && <>
          <div className="fulllayout-height">
            <div onClick={e => { window.location.href = appUrls.outerDomainUrls.brookParent  }} style={{ cursor: 'pointer' }}>
              {/* <img src={Icon} alt="logo" /> */}
            </div>
          </div>
          {/* <div className={`${theme.theme}-loader-global top-loader`} style={{ 'width': `${theme.percentage}%` }} /> */}
        </>
      }
    </div>
  );
};